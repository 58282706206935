<template>
  <div>
    <app-page-edit page-title="Edit Berita Acara" page-toolbar-title="Edit Berita Acara">
      <template #toolbar>
        <app-button @click="getDetail(true)" title="Refresh" mdi-icon="mdi-refresh" :loading="loading"></app-button>
        <app-button @click="printDocument()" title="Print" mdi-icon="mdi-printer"></app-button>
        <app-log-data-dialog module_name="beritaacara" :id="berita_acara_header.id">
          <app-button title="Document Log" mdi-icon="mdi-clipboard-text-clock"></app-button>
        </app-log-data-dialog>
        <v-divider vertical class="mx-2"></v-divider>
        <app-button @click="redirect('Accounting.BeritaAcara.Detail', { id: id })" title="Detail" mdi-icon="mdi-file-find"></app-button>
        <app-button :loading="loading" v-if="berita_acara_header.status == 'open'" @click="saveData()" mdi-icon="mdi-content-save" title="Save"></app-button>
      </template>

      <v-row>
        <v-col cols="12" lg="9">
          <v-row v-if="(loading == false) && (typeof berita_acara_header.status !== 'undefined') && (berita_acara_header.status != 'open')">
            <v-col>
              <v-alert color="info" dark>
                <p>Document status must be open! <a href="javascript:;" @click="$router.go(-1)">Back</a> </p>
              </v-alert>
            </v-col>
          </v-row>

          <v-row v-if="(notFound == true) && (typeof berita_acara_header.status === 'undefined')">
            <v-col>
              <v-alert color="warning" dark>
                <p>Document status must be open! <a href="javascript:;" @click="$router.go(-1)">Back</a> </p>
              </v-alert>
            </v-col>
          </v-row>

          <v-row v-if="berita_acara_header.status == 'open'">
            <v-col cols="12">
              <!-- General -->
              <card-expansion title="General" class="mb-3">
                <v-row>
                  <v-col class="py-0" cols="12" md="6">
                    <v-text-field dense disabled v-model="berita_acara_header.document_no_" placeholder="No." label="No." readonly filled outlined hide-details class="mb-2"></v-text-field>
                  </v-col>
                  <v-col class="py-0" cols="12" md="6">
                    <v-text-field dense disabled v-model="berita_acara_header.document_date" placeholder="Doc. Date" label="Doc. Date" readonly filled outlined hide-details class="mb-2"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="py-0" cols="12" md="6">
                    <v-text-field dense disabled v-model="berita_acara_header.document_type" placeholder="Document Type" label="Document Type" readonly filled outlined hide-details class="mb-2"></v-text-field>
                  </v-col>
                  <v-col class="py-0" cols="12" md="6">
                    <v-text-field dense disabled v-model="berita_acara_header.source_document" placeholder="Source Document" label="Source Document" readonly filled outlined hide-details class="mb-2"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="py-0" cols="12" md="6">
                    <v-text-field dense disabled v-model="berita_acara_header.area_code" placeholder="Area Code" label="Area Code" readonly filled outlined hide-details class="mb-2"></v-text-field>
                  </v-col>
                  <v-col class="py-0" cols="12" md="6">
                    <v-text-field dense disabled v-model="berita_acara_header.status" placeholder="Status" label="Status" readonly filled outlined hide-details class="mb-2"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="py-0" cols="12" md="6">
                    <v-text-field dense disabled v-model="berita_acara_header.created_by_username" placeholder="Created By" label="Created By" readonly filled outlined hide-details class="mb-2"></v-text-field>
                  </v-col>
                  <v-col class="py-0" cols="12" md="6">
                    <v-text-field dense disabled v-model="berita_acara_header.created_date" placeholder="Created Date" label="Created Date" readonly filled outlined hide-details class="mb-2"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="py-0" cols="12" md="6">
                    <v-textarea dense rows="2" v-model="berita_acara_header.description" placeholder="Description" label="Description" outlined hide-details class="mb-2"></v-textarea>
                  </v-col>
                  <v-col class="py-0" cols="12" md="6">
                    <v-dialog ref="refModal1" v-model="modal1" :return-value.sync="berita_acara_header.target_date" persistent width="290px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field dense v-model="berita_acara_header.target_date" placeholder="Target Date" label="Target Date" readonly outlined hide-details class="mb-2" v-bind="attrs" v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="berita_acara_header.target_date" scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="modal1 = false">Cancel</v-btn>
                        <v-btn text color="primary" @click="
                          $refs.refModal1.save(berita_acara_header.target_date)
                          ">OK</v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                </v-row>
              </card-expansion>
              <!-- Items -->
              <card-expansion title="Items" class="mb-3">
                <template slot="action">
                  <AddItemVue :document_date="berita_acara_header.document_date" :addItemCallback="addItem">
                    <v-btn icon>
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </AddItemVue>
                </template>
                <v-row>
                  <v-col class="py-0 px-0">
                    <v-card elevation="0" outlined>
                      <v-data-table :headers="berita_acara_item_columns" :items="berita_acara_item_data">
                        <template v-slot:[`item.item_image`]="{ item }">
                          <image-viewer :src="resourceUrl(item.item_image)" :title="item.item_description + ' - ' + item.description">
                            <v-img :src="resourceUrl(item.item_image)" max-width="60" max-height="60" class="mx-auto"></v-img>
                          </image-viewer>
                        </template>
                        <template v-slot:[`item.item_description`]="{ item }">
                          <EditItem :selectedItemData="item" @editItemCallback="updateItem">
                            <a href="javascript:;">{{ item.item_description }}</a>
                          </EditItem>
                        </template>
                        <template v-slot:[`item.weight`]="{ item }">
                          <span>{{ numberFormat(item.weight, 2) }}</span>
                        </template>
                        <template v-slot:[`item.quantity`]="{ item }">
                          <span>{{ numberFormat(item.quantity, 2) }}</span>
                        </template>
                        <template v-slot:[`item.unit_price`]="{ item }">
                          <span>{{ numberFormat(item.unit_price, 2) }}</span>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                          <div class="d-flex">
                            <EditItem :selectedItemData="item" @editItemCallback="updateItem">
                              <a href="javascript:;">
                                <v-icon small class="mr-2" color="primary">mdi-pencil</v-icon>
                              </a>
                            </EditItem>
                            <v-icon small @click="deleteItem(item)" color="error">mdi-delete</v-icon>
                          </div>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-col>
                </v-row>
              </card-expansion>

              <!-- Attachment -->
              <card-expansion title="Attachment" class="mb-3">
                <template slot="action">
                  <AddAttachment :callback="addAttachment">
                    <v-btn icon>
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </AddAttachment>
                </template>
                <v-row>
                  <v-col class="py-0">
                    <v-list>
                      <v-list-item v-for="(attachment, i) in berita_acara_attachment" :key="i" style="
                      border: 1px solid #ddd;
                      border-radius: 5px;
                      margin-bottom: 5px;
                    ">
                        <v-list-item-content>
                          <v-row>
                            <v-col cols="12" md="6">
                              <v-list-item-title>{{ attachment.title }}</v-list-item-title>
                              <v-list-item-subtitle>{{ attachment.description }}</v-list-item-subtitle>
                            </v-col>
                            <v-col cols="12" md="6">
                              <div :class="(!isMobile) ? 'd-flex float-right' : ''">
                                <v-btn link dense small target="_blank" :href="resourceUrl(attachment.file_src)" icon>
                                  <v-icon small>mdi-download</v-icon>
                                </v-btn>
                                <EditAttachment :selectedItemData="attachment" :callback="updateAttachment">
                                  <v-btn link dense small icon color="primary">
                                    <v-icon small>mdi-pencil</v-icon>
                                  </v-btn>
                                </EditAttachment>

                                <v-btn link dense small icon color="error">
                                  <v-icon small @click="deleteAttachment(attachment)">mdi-delete</v-icon>
                                </v-btn>
                              </div>
                            </v-col>
                          </v-row>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                </v-row>
              </card-expansion>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" lg="3">
          <document-info :value="berita_acara_header"></document-info>

          <app-comment :document_id="parseInt(berita_acara_header.id)" :source_document="berita_acara_header.source_document" :document_no_="berita_acara_header.document_no_"></app-comment>
        </v-col>
      </v-row>



    </app-page-edit>
  </div>
</template>

<script>
import AddItemVue from "./components/AddItem";
import EditItem from "./components/EditItem";
import AddAttachment from "./components/AddAttachment";
import EditAttachment from "./components/EditAttachment";
import DocumentInfo from './components/DocumentInfo.vue';

export default {
  components: {
    AddItemVue,
    EditItem,
    AddAttachment,
    EditAttachment,
    DocumentInfo,
  },
  data() {
    return {
      id: 0,
      loading: false,
      notFound: false,
      modal1: false,
      berita_acara_header: {},
      berita_acara_item_columns: [
        { text: "Image", value: "item_image", sortable: false },
        { text: "Item No.", value: "item_no_", sortable: false },
        {
          text: "Item Description",
          value: "item_description",
          sortable: false,
        },
        { text: "UoM", value: "unit_of_measure", sortable: false },
        { text: "Quantity", value: "quantity", sortable: false },
        { text: "Weight", value: "weight", sortable: false },
        { text: "Unit Price", value: "unit_price", sortable: false },
        // { text: "Location Code", value: "location_code", sortable: false },
        // { text: "Bin Code", value: "bin_code", sortable: false },
        { text: "Description", value: "description", sortable: false },
        { text: "Actions", value: "actions", sortable: false },
      ],
      berita_acara_item_data: [],
      berita_acara_user: {},

      berita_acara_attachment: [],

      saveControl: false,
    };
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    berita_acara_header(n, o) {
      this.saveControl = true
    }
  },
  methods: {
    async getData(refresh = false) {
      if (refresh == true)
        this.AxiosStorageRemove("GET", "beritaacara/detail");

      this.loading = true;
      this.notFound = false;
      this.showLoadingOverlay(true);

      var config = {
        params: {
          id: this.id,
        },
      };
      await this.$axios
        .get("beritaacara/detail", config)
        .then((res) => {
          this.berita_acara_header = res.data.data.berita_acara_header;
          this.berita_acara_item_data = res.data.data.berita_acara_item;
          this.berita_acara_user = res.data.data.user;
          this.berita_acara_attachment = res.data.data.berita_acara_attachment;
          this.showLoadingOverlay(false);
          this.loading = false;
          this.notFound = false;
        })
        .catch((error) => {
          if(error.response.status == 404){
            this.notFound = true;
          }
          this.showLoadingOverlay(false);
          this.loading = false;
          this.axiosErrorHandler(error);
        });
    },

    async saveData() {
      this.loading = true;
      this.showLoadingOverlay(true);
      var formData = new FormData();
      formData.append(
        "berita_acara_header",
        JSON.stringify(this.berita_acara_header)
      );
      formData.append(
        "berita_acara_item",
        JSON.stringify(this.berita_acara_item_data)
      );
      formData.append(
        "berita_acara_attachment",
        JSON.stringify(this.berita_acara_attachment)
      );

      await this.$axios
        .post("beritaacara/save", formData)
        .then((res) => {
          this.loading = false;
          this.showLoadingOverlay(false);

          var resData = res.data;

          if (resData.status == "success") {
            this.showAlert(resData.status, resData.message);

            this.goBack()
          }
        })
        .catch((error) => {
          this.loading = false;
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },

    addItem(item) {
      var exists = false;

      this.berita_acara_item_data.forEach((_item) => {
        if (_item.item_no_ == item.item_no_) {
          exists = true;
        }
      });

      if (!exists) {
        this.berita_acara_item_data.push(item);
      } else {
        this.showAlert("warning", "This item is already exists!");
      }
    },

    updateItem(item) {
      var exists = false;

      var ol = this.berita_acara_item_data;
      var nl = [];

      ol.forEach((_item) => {
        if (_item.item_no_ == item.item_no_) {
          exists = true;
          _item = item;
        }

        nl.push(_item);
      });

      if (!exists) {
        this.showAlert("warning", "This item is not exists!");
      }

      this.berita_acara_item_data = nl;
    },

    deleteItem(item) {
      if (!confirm('Delete Item "' + item.item_no_ + '"')) return;

      var exists = false;

      var ol = this.berita_acara_item_data;
      var nl = [];

      ol.forEach((_item) => {
        if (_item.item_no_ != item.item_no_) {
          nl.push(_item);
        }

        if (_item.item_no_ == item.item_no_) {
          exists = true;
        }
      });

      if (!exists) {
        this.showAlert("warning", "This item is not exists!");
      }

      this.berita_acara_item_data = nl;
    },

    addAttachment(item) {
      var exists = false;

      this.berita_acara_attachment.forEach((_item) => {
        if (_item.file_src == item.file_src) {
          exists = true;
        }
      });

      if (!exists) {
        this.berita_acara_attachment.push(item);
      } else {
        this.showAlert("warning", "This attachment is already exists!");
      }
    },

    updateAttachment(item) {
      var exists = false;

      var ol = this.berita_acara_attachment;
      var nl = [];

      ol.forEach((_item) => {
        if (_item.file_src == item.file_src) {
          exists = true;
          _item = item;
        }

        nl.push(_item);
      });

      if (!exists) {
        this.showAlert("warning", "This attachment is not exists!");
      }

      this.berita_acara_attachment = nl;
    },

    deleteAttachment(item) {
      if (!confirm('Delete Item "' + item.title + '"')) return;

      var exists = false;

      var ol = this.berita_acara_attachment;
      var nl = [];

      ol.forEach((_item) => {
        if (_item.file_src != item.file_src) {
          nl.push(_item);
        }

        if (_item.file_src == item.file_src) {
          exists = true;
        }
      });

      if (!exists) {
        this.showAlert("warning", "This attachment is not exists!");
      }

      this.berita_acara_attachment = nl;
    },

    printDocument() {
      this.redirect('Accounting.BeritaAcara.PrintDocument', { id: this.id });
    },
  },
  mounted() {
    this.id = this.$route.query.id;

    this.getData();
  },
};
</script>
